import styled from 'styled-components';

export const CopyPageSection = styled.div`
  .section-banner {
    height: calc(100vh - 80px);
    background-color: var(--hero-overlay);
    &-text {
      position: absolute;
      left: 50px;
      bottom: 90px;
      color: var(--white);
      max-width: 50vw;
      
      @media (max-width: 1023px) {
        max-width: 80%;
        left: 25px;
        bottom: 50px;
      }
    }
    h1 {
      margin: 0;
    }
    .gatsby-image-wrapper {
      position: relative;
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    .overlay {
      background: var(--copy-overlay);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      clip-path: polygon(0 0, 40% 0, 80% 100%, 0% 100%);
      
      @media (min-width: 1024px) {
       clip-path: polygon(0 0, 21% 0, 70% 100%, 0% 100%);
      }
      @media (max-width: 768px)  {
        clip-path: none;
        opacity: 0.2;
      }
    }
  }
  .section-intro, .section-blocks {
    padding: 80px 50px 80px;
    @media (max-width: 1023px) {
      padding: 40px 25px;
    }
    h2 {
      margin-top: 0;
    }
  }
`;