import React from 'react';

export default function CopyIntro({ data, pageContext }) {
  return (
    <div className="section-intro dev">
      <h2>{data.title[pageContext]}</h2>
      <p>{data.subtitle[pageContext]}</p>
      <p>{data.description[pageContext]}</p>
    </div>
  );
}
